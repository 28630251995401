import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// SERVICE
import { CaseService } from '../../service/case.service';
import { SessionService } from '../../service/session.service';

// DTO
import { CaseResponseDto } from '../../service/dto/case.dto';
import { CreateScheduleRequestDto, SessionResponseDto } from '../../service/dto/session.dto';

// UI COMPONENTS
import CustomSelect, { ICustomSelectItem } from '../../components/CustomSelect';
import { IEntitySelectItem } from '../../components/EntitySelect/EntitySelect';
import InputLabel from '../../components/InputLabel';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';
import CaseInfo from './CaseInfo';

// UTILS
import { SessionType, USER_ROLES } from '../../common/enums';
import { IRouteComponent } from '../../routes';
import { addMinutes, toLocaleDateTimeString } from '../../utils';
import { setUserRole } from '../../utils/role';

import { DateTimePicker } from 'react-rainbow-components';
import TextInput from '../../components/TextInput';
import Alert from '../../utils/alert';
import { WsType } from '../../common/interfaces';
import { WebSocketContext } from '../../context/socket';

const initialValue = {
  coach: '',
  startDate: new Date(),
  caseTemplate: '',
  type: SessionType.RegularSession,
  location: '',
};

const CreateSessionSlotForm = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { type } = useParams<{ type: string }>();
  const [roles, setRoles] = useState<USER_ROLES[]>([USER_ROLES.Trainee]);
  const socket: WsType | null = useContext(WebSocketContext);

  // Form data
  const [data, setData] = useState<CreateScheduleRequestDto>({
    ...initialValue,
    type: type === 'adhoc' ? SessionType.AdHocSession : SessionType.RegularSession,
  });
  const [startDate, setStartDate] = useState<Date>();

  // Case data
  const [certifiedCaseList, setCertifiedCaseList] = useState<ICustomSelectItem[]>([]);
  const [selectedCase, setSelectedCase] = useState<string>('');
  const [caseData, setCaseData] = useState<CaseResponseDto>();
  const [location, setLocation] = useState<string>('');

  const locale = (navigator && navigator.language) || 'de';

  // Fetch
  useEffect(() => {
    const userRoles = setUserRole({ currentUser, currentInstitution });
    setRoles(userRoles);
    serviceGetCaseList();
    const date: Date = new Date(new Date().getDate() + 1);
  }, []);

  useEffect(() => {
    CaseService.getById(
      selectedCase,
      (data: CaseResponseDto) => {
        setCaseData(data);
      },
      error => {},
    );
  }, [certifiedCaseList, selectedCase]);

  useEffect(() => {
    if (caseData) {
      setData({ ...data, coach: currentUser.id, caseTemplate: caseData.id });
    }
  }, [caseData]);

  const serviceGetCaseList = () => {
    CaseService.listCertified(
      { search: '', rolesFilter: roles },
      (data: Array<CaseResponseDto>) => {
        const modifiedCaseList = data.map(v => {
          return { value: v.id, label: v.name };
        });
        setSelectedCase(modifiedCaseList[0].value ? modifiedCaseList[0].value : '');
        setCertifiedCaseList(modifiedCaseList);
      },
      () => {},
    );
  };

  const serviceCreate = () => {
    const requestData = {
      coach: currentUser.id,
      startDate: startDate ?? new Date(),
      caseTemplate: selectedCase,
      type: type === 'adhoc' ? SessionType.AdHocSession : SessionType.RegularSession,
      location,
    };
    SessionService.create(
      requestData,
      (data: SessionResponseDto) => {
        navigate(data.type === SessionType.RegularSession ? '/schedule' : '/live-sessions');
        Alert.success(t('successMessages.sessionSlotCreated'));
      },
      error => {
        console.log(error);
        Alert.warning(t('errorMessages.sessionSlotNotCreated'));
      },
    );
  };
  const handleNewSessionSubmit = () => {
    serviceCreate();
  };

  const renderEndDate = (): string => {
    if (!caseData) return '';
    if (startDate) {
      const endDate: Date = addMinutes(startDate, caseData.interview_duration + caseData.feedback_duration);
      return toLocaleDateTimeString(endDate);
    }
    return '';
  };

  return (
    <div className='my-6 mx-4 2xl:mx-6'>
      <form className='w-full'>
        <div className='flex items-center justify-between pt-2'>
          <div>
            <div className='font-bold text-xl text-black'>
              {t(data.type === SessionType.RegularSession ? 'modals.addSessionSlot' : 'modals.addLiveSlot')}
            </div>

            <div className='uppercase text-3.25 text-gray-dark'>
              {t('cases.createdBy')} {currentUser.firstName} {currentUser.lastName}
            </div>
          </div>

          <div className='flex items-center'>
            <SecondaryButton
              title={t('buttons.cancel')}
              className='text-primary border-primary hover:text-primary hover:bg-background-black mr-4'
              onClick={() => {
                navigate(data.type === SessionType.RegularSession ? '/schedule' : '/live-sessions');
              }}
            />
            <PrimaryButton
              title={t(
                data.type === SessionType.RegularSession
                  ? 'buttons.publishSessionSlot'
                  : 'buttons.publishLiveSessionSlot',
              )}
              icon='bi-check2-circle'
              onClick={() => handleNewSessionSubmit()}
              disabled={data.type === SessionType.RegularSession && startDate == null}
            />
          </div>
        </div>
        <hr className='w-full h-px bg-black-divider border-0 my-6'></hr>
        <div className='flex justify-between'>
          <div className='w-6/12 lg:w-5/12'>
            <div className='mb-6'>
              <InputLabel label={t('inputLabels.selectCase')} otherMB='mb-1' />
              <CustomSelect
                placeholder={t('placeholders.selectCase')}
                list={certifiedCaseList}
                handleChange={(item: IEntitySelectItem) => {
                  if (item.value) {
                    setSelectedCase(item.value);
                  }
                }}
                value={selectedCase}
              />
            </div>

            {caseData && <CaseInfo data={caseData} />}
          </div>
          {data.type === SessionType.RegularSession && (
            <div className='w-5/12 lg:w-4/12'>
              <div className='w-full mb-6'>
                <InputLabel label={t('inputLabels.selectDate')} otherMB='mb-1' />

                <div className='rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto'>
                  <DateTimePicker
                    required
                    value={startDate}
                    placeholder={t('placeholders.startDate')}
                    onChange={value => setStartDate(value)}
                    borderRadius='semi-square'
                    locale={locale}
                    hour24
                    minDate={new Date()}
                  />
                </div>
              </div>
              <div className='w:full mb-6'>
                <InputLabel
                  label={t('inputLabels.endTime')}
                  additionalLabel={t('inputLabels.autoSelected')}
                  otherMB='mb-1'
                />
                <div className='md-form relative custom-date'>
                  <i className='bi bi-clock-fill absolute z-10 text-gray text-lg top-1.5 left-3.5'></i>
                  <input
                    type='text'
                    className='flex custom-input transition text-base py-1.5 px-3 block rounded-lg bg-clip-padding w-full font-normal relative custom-date_input text-gray placeholder-gray-light pl-12 pr-2.5 shadow border border-solid border-black-divider focus:border-primary-hover focus:outline-none appearance-none min-h-38'
                    value={renderEndDate()}
                    disabled
                  />
                </div>
              </div>
              <div>
                <InputLabel label={t('session.location.label')} additionalLabel={t('session.location.optional')} />
                <p className='text-black text-lg mb-2'>{t('session.location.details')}</p>
                <TextInput
                  value={location}
                  placeholder={t('session.location.placeholder')}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setLocation(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateSessionSlotForm;
