import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RecordStatus, USER_ROLES, USER_STATUS } from '../../common/enums';
import { ICurrentInstitution, ICurrentUser } from '../../common/interfaces';
import InputLabel from '../../components/InputLabel';
import RecordedOnList from '../../components/RecordedOnList';
import Tag, { TagType } from '../../components/Tag';
import TeachableMomentList from '../../components/TeachableMomentList';
import { setSessionEvaluation, setSessionResult, setSessionTotalScore } from '../../redux/actions';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { SessionEvaluationState } from '../../redux/types';
import { CaseResultDto } from '../../service/dto/case.dto';
import { SessionEvaluationResponse } from '../../service/dto/session-evaluate.dto';
import {
  ScoreResultDto,
  SessionRecordDownloadResponseDto,
  SessionRecordResponseDto,
  SessionResponseDto,
  TeachableMomentDto,
} from '../../service/dto/session.dto';
import { SessionEvaluateService } from '../../service/session-evaluate.service';
import { SessionService } from '../../service/session.service';
import { decimalToPercent, getDateAndTimeDuration, getScoreValuesLength, toLocaleDateTimeString } from '../../utils';
import { DurationMS } from '../../utils/constants';
import { IRouteComponent } from './../../routes';
import RecordsTabs, { RecordTabType, TabContent } from './record/RecordsTabs';

const SessionResult = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [session, setSession] = useState<SessionResponseDto>();
  const [caseData, setCaseData] = useState<CaseResultDto>();
  const [scoreResult, setScoreResult] = useState<ScoreResultDto | undefined>();
  const [editRatingsPermission, setEditRatingPermission] = useState<boolean>(false);
  const [teachableMoments, setTeachableMoments] = useState<TeachableMomentDto[]>();
  const [isRecordTabSelected, setIsRecordTabSelected] = useState<boolean>(true);
  const sessionEvaluateState: SessionEvaluationState = useAppSelector((state: any) => state.sessionEvaluationReducer);
  const [sessionTotalScore, setSessionTotalScore] = useState(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (session) {
      setSessionTotalScore(session.totalScore);
    }
  }, [session]);

  const isUserInstitutionAdmin = () => {
    const user = currentUser as ICurrentUser;
    const institution = currentInstitution as ICurrentInstitution;
    if (!user || !institution) {
      return false;
    }
    const index = user.institutions.findIndex(item => item.institutionId == institution.id);

    if (index == -1) {
      return false;
    }
    const institutionRelation = user.institutions[index]!;
    if (institutionRelation.status != USER_STATUS.Active) {
      return false;
    }

    return institutionRelation.roles.indexOf(USER_ROLES.Admin) != -1;
  };

  const handleScoreUpdate = (itemId: string, newValue: number) => {
    if (!scoreResult || scoreResult[itemId] === undefined) {
      return;
    }

    const updatedScoreResult = { ...scoreResult };
    updatedScoreResult[itemId].value = newValue;
    setScoreResult(updatedScoreResult);
    SessionService.getById(
      id as string,
      (data: SessionResponseDto) => {
        setSessionTotalScore(data.totalScore);
      },
      () => {},
    );
  };

  const serviceFetchSession = (sessionId: string) => {
    SessionService.getById(
      sessionId,
      (data: SessionResponseDto) => {
        setSession(data);
        setCaseData({
          id: data.caseTemplateSummary.id,
          name: data.caseTemplateSummary.name,
          description: data.caseTemplateSummary.description,
          interview_duration: data.caseTemplateSummary.interview_duration,
          feedback_duration: data.caseTemplateSummary.feedback_duration,
          scoring_items: data.scoreResult,
        });
        setScoreResult(data.scoreResult);
        setTeachableMoments(data.teachableMoments);
        serviceFetchSessionEvaluation(sessionId);
      },
      error => {},
    );
  };

  const serviceFetchSessionEvaluation = (sessionId: string) => {
    SessionEvaluateService.getBySessionId(
      sessionId,
      (data: SessionEvaluationResponse) => {
        dispatch(setSessionEvaluation({ sessionEvaluation: data }));
      },
      () => {},
    );
  };

  const renderSessionRecords = (): React.ReactElement | null => {
    if (!session) {
      return null;
    }

    const filteredRecords: TabContent[] = [
      {
        title: t('session.result.tabs.coaching'),
        type: RecordTabType.RecordType,
        data: {
          isReadonly: false,
          record: session.dailyCoachingSessionRecord ?? session.coachingSessionRecord,
          score: scoreResult,
          teachableMoments: session.teachableMoments,
          type: 'coaching',
        },
      },
      {
        title: t('session.result.tabs.feedback'),
        type: RecordTabType.RecordType,
        data: {
          isReadonly: false,
          record: session.dailyFeedbackSessionRecord ?? session.feedbackSessionRecord,
          type: 'feedback',
        },
      },
      {
        title: t('session.result.tabs.evaluation'),
        type: RecordTabType.SessionEvaluate,
        data: {
          isCoach: currentUser.id === session.coach.id,
          session: session,
          sessionEvaluate: sessionEvaluateState.sessionEvaluation as SessionEvaluationResponse,
        },
      },
    ].filter(x => x.type !== RecordTabType.RecordType || x.data.record);
    if (!filteredRecords.length) {
      return null;
    }
    return <RecordsTabs tabs={filteredRecords} onTabSelected={onTabChanged} sessionId={id as string} />;
  };

  const onTabChanged = (selectedTab: TabContent) => {
    dispatch(
      setSessionResult({
        sessionRecord: {
          coachingSession: {
            setTimeManipulation: 0,
            setMuted: false,
            setPlaying: false,
          },
        },
      }),
    );
    setIsRecordTabSelected(selectedTab.type === RecordTabType.RecordType);
  };

  const renderTime = (item: SessionResponseDto) => {
    const startDate = new Date(item.startDate);
    return `⏱️ ${toLocaleDateTimeString(startDate)}`;
  };

  // Fetch
  useEffect(() => {
    serviceFetchSession(id as string);
    return () => {
      dispatch(
        setSessionResult({
          sessionRecord: {
            coachingSession: {
              setPlaying: false,
            },
          },
        }),
      );
    };
  }, []);

  useEffect(() => {
    if (session) {
      setEditRatingPermission(
        currentUser.isSuperAdmin || currentUser.id == session.coach.id || isUserInstitutionAdmin(),
      );
    }

    return () => {
      dispatch(
        setSessionResult({
          sessionRecord: {
            coachingSession: {
              setPlaying: false,
            },
          },
        }),
      );
    };
  }, [session]);

  const renderLocation = (location?: string) => {
    console.log(location);
    const isOffline = !(location === '' || location === undefined);

    return (
      <p className='text-lg mb-2'>
        <span className={`text-lg mb-6 text-${isOffline ? 'negative-darker' : 'black'}`}>
          {isOffline ? `📍 ${location?.toUpperCase()}` : '🌐 ONLINE'}
        </span>{' '}
        {isOffline && <span className='text-gray'>{t('session.location.offline')}</span>}
      </p>
    );
  };

  return (
    <div className='my-6 mx-4 2xl:mx-6'>
      <div className='flex justify-between'>
        <div className='flex-8 mr-12'>
          {session && caseData && (
            <>
              {renderSessionRecords()}
              {isRecordTabSelected && (
                <div>
                  <div className='my-2 fs-5'>
                    <InputLabel
                      label={'Scoring Items'}
                      additionalLabel={
                        caseData &&
                        `${getScoreValuesLength(scoreResult!) ?? 0}/${
                          Object.values(caseData.scoring_items).length ?? 0
                        }`
                      }
                      otherMB='mb-4'
                    />
                  </div>
                  <RecordedOnList
                    scoreResult={scoreResult}
                    editRatingsPermission={editRatingsPermission}
                    sessionFromResult={session}
                    recordStatus={session.coachingSessionRecord?.status ?? session.dailyCoachingSessionRecord?.status}
                    isReplay={true}
                    afterUpdateCallback={handleScoreUpdate}
                    onClick={(ms: number) => {
                      const newTimeInSeconds = Math.max(0, Math.floor(ms / DurationMS.SEC));
                      dispatch(
                        setSessionResult({
                          sessionRecord: {
                            coachingSession: {
                              setTimeManipulation: Math.max(0, newTimeInSeconds - 15),
                              setMuted: false,
                              setPlaying: true,
                            },
                          },
                        }),
                      );
                    }}
                    isStar
                  />
                </div>
              )}
            </>
          )}
          {session && teachableMoments && isRecordTabSelected && teachableMoments.length > 0 && (
            <>
              <div className='my-2 fs-5'>
                <InputLabel label={t('inputLabels.teachableMoments')} otherMB='mb-4' />
              </div>
              <TeachableMomentList isIcon sessionFromResult={session} teachableMoments={teachableMoments} />
            </>
          )}
        </div>
        {session && caseData && (
          <div className='flex-5'>
            <div className='flex justify-between'>
              <div className='font-bold text-xl text-black'>{t('session.result.sessionDetails')}</div>
              <Tag label={t('session.result.finished')} type={TagType.Blue} />
            </div>
            <hr className='w-full h-px bg-black-divider border-0 my-4'></hr>
            <InputLabel label={t('inputLabels.score')} />
            <p className='font-bold text-warning-darker text-lg'>{decimalToPercent(sessionTotalScore)} %</p>
            <hr className='w-full h-px bg-black-divider border-0 my-4'></hr>
            <InputLabel label={t('inputLabels.sessionCase')} />
            <p className='font-bold text-lg text-black'>{caseData.name}</p>
            <hr className='w-full h-px bg-black-divider border-0 my-4'></hr>
            <InputLabel label={t('inputLabels.location')} />
            <p className='font-bold text-lg text-black'>{renderLocation(session.location)}</p>
            <hr className='w-full h-px bg-black-divider border-0 my-4'></hr>
            <InputLabel label={t('inputLabels.timeAndDate')} />
            <p className='font-bold text-lg text-black'>{renderTime(session)}</p>
            <hr className='w-full h-px bg-black-divider border-0 my-4'></hr>
            <div>
              <InputLabel label={t('inputLabels.participants')} />
              <div className='mb-4'>
                {session.coach && (
                  <p>
                    <span className='text-black text-lg'>{`👨‍🏫 ${session.coach.firstName} ${session.coach.lastName}`}</span>
                  </p>
                )}
                {session.trainee && (
                  <p>
                    <span className='text-black text-lg'>
                      {`🎓 ${session.trainee.firstName} ${session.trainee.lastName}`}
                    </span>
                  </p>
                )}
              </div>

              <InputLabel label={t('inputLabels.sessionLength')} />
              <p className='text-black text-lg mb-4'>
                {`${t('inputLabels.interview')} ${caseData.interview_duration} ${t('table.min')}`} < br/>
                {`${t('inputLabels.feedback')} ${caseData.feedback_duration} ${t('table.min')}`}
              </p>

              <InputLabel label={t('inputLabels.description')} />
              <p className='text-black text-lg mb-4'>{caseData.description}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SessionResult;
