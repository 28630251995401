export enum USER_ROLES {
  Admin = 'role:admin',
  Trainee = 'role:trainee',
  SP = 'role:sp',
  Spectator = 'role:spectator',
  SuperAdmin = 'role:superadmin',
}

export enum USER_STATUS {
  Invited = 'status:invited',
  Active = 'status:active',
  Disabled = 'status:disabled',
}

export enum CASE_STATE {
  Draft = 'status:draft',
  Published = 'status:published',
}

export enum CASE_NOTE {
  None = 'note:none',
  Open = 'note:open',
  Patient = 'note:patient',
}

export enum SessionStatus {
  Open = 'status:open',
  Scheduled = 'status:scheduled',
  Coaching = 'status:video:coaching',
  Feedback = 'status:video:feedback',
  Finished = 'status:finished',
}

export enum SessionType {
  RegularSession = 'session-type:regular',
  AdHocSession = 'session-type:adhoc',
}

export enum SessionVideoType {
  Coaching = 'session:video:type:coaching',
  Feedback = 'session:video:type:feedback',
}

export enum ScoreTypes {
  Good = 3,
  Satisfactory = 2,
  Unsatisfactory = 1,
  None = 0,
  Unset = 0.5,
}

export enum RecordStatus {
  Null = 'record:null',
  Enqueued = 'record:enqueued',
  Started = 'record:started',
  Progress = 'record:progress',
  Available = 'record:available',
  Failed = 'record:failed',
  Aborted = 'record:aborted',
}

export enum RecordFailedReason {
  Null = 'record:failed:reason:null',
  ServiceError = 'record:failed:reason:service_error',
  ExternalServiceError = 'record:failed:reason:external_service_error',
}

export enum COMPONENT_STATE {
  STREAM,
  WAITING,
  ERROR,
  END_SESSION,
}

export enum CaseScoringFloat {
  None = 'case-scoring-float-position:none',
  Left = 'case-scoring-float-position:left',
  Right = 'case-scoring-float-position:right',
}

export enum SessionFilterLocation {
  Any = 'filter-location:any',
  Online = 'filter-location:online',
  Offline = 'filter-location:offline',
}
