import { ApiBaseService } from './api.service';
import { SessionLogExportFilterDto } from './dto/session-log-export.filter.dto';

import {
  CreateScheduleRequestDto,
  RequestTimeSlotsRequest,
  SessionAddParticipantRequestDto,
  SessionHistoryRequestDto,
  SessionInstitutionResponseDto,
  SessionListQueryDto,
  SessionLogRequestDto,
  SessionRecordDownloadResponseDto,
  SessionRecordRecordingResponseDto,
  SessionRecordResponseDto,
  SessionResponseDto,
  SessionScoreRequestDto,
  SessionTeachableMomentRequestDto,
  TeachableMomentDto,
} from './dto/session.dto';
import { VideoRoomSummary } from './dto/video-room-summary.interface.dto';
import { InsightsEvaluationCoachFilterDto } from './dto/insights-export.filter.dto';
import { InviteCoachDto } from './dto/invite-coach.dto';
import { VideoRoomTokenResponseDto } from './dto/video-room.dto';

export class SessionService {
  public static basePath() {
    if (!process.env.API_URL_SESSION_BASE) {
      throw new Error('API_URL_SESSION_BASE does not exist');
    }
    return process.env.API_URL_SESSION_BASE;
  }

  public static create(
    payload: CreateScheduleRequestDto,
    handleSuccess: (_data: SessionResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath();

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: SessionResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static book(
    id: string,
    handleSuccess: (_data: SessionResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'book/' + id;

    ApiBaseService.Post(
      uriPath,
      {},
      (_code: number, _data: SessionResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static requestSessionSlot(
    payload: RequestTimeSlotsRequest,
    handleSuccess: () => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'request-timeslots/';

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: SessionResponseDto) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static assignTrainee(
    data: { sessionId: string; traineeId: string },
    handleSuccess: (_data: SessionResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'assignTrainee/';

    ApiBaseService.Post(
      uriPath,
      data,
      (_code: number, _data: SessionResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static cancel(
    id: string,
    handleSuccess: (_data: SessionResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'cancel/' + id;

    ApiBaseService.Post(
      uriPath,
      {},
      (_code: number, _data: SessionResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static sendInvite(
    payload: InviteCoachDto,
    handleSuccess: (_code: number) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'invite';

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static list(
    payload: SessionListQueryDto,
    handleSuccess: (_data: Array<SessionResponseDto>) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'list';

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static count(
    payload: SessionListQueryDto,
    handleSuccess: (_data: number) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'list/count';

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static history(
    payload: SessionHistoryRequestDto,
    handleSuccess: (_data: Array<SessionResponseDto>) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'history';

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static historyCount(
    payload: SessionHistoryRequestDto,
    handleSuccess: (_data: number) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'history/count';

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static log(
    payload: SessionLogRequestDto,
    handleSuccess: (_data: Array<SessionResponseDto>) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'log';

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static logCount(
    payload: SessionLogRequestDto,
    handleSuccess: (_data: number) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'log/count';

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static xls(payload: {}, handleSuccess: (_data: any) => void, handleError: (err: Error) => void) {
    const uriPath = SessionService.basePath() + 'xls';

    ApiBaseService.Get(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getById(
    id: string,
    handleSuccess: (_data: SessionInstitutionResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + id;
    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getShareToken(id: string) {
    const uriPath = SessionService.basePath() + id + '/shareToken';
    return new Promise<string>((resolve, reject) =>
      ApiBaseService.Get(
        uriPath,
        {},
        (_code: number, _data: string) => resolve(_data),
        (err: Error) => reject(err),
      ),
    );
  }

  public static validateShareToken(id: string, shareToken: string) {
    const uriPath = SessionService.basePath() + id + '/validateShareToken/' + shareToken;
    return new Promise<{ session: SessionResponseDto; room: VideoRoomSummary }>((resolve, reject) =>
      ApiBaseService.Get(
        uriPath,
        {},
        (_code: number, _data: { session: SessionResponseDto; room: VideoRoomSummary }) => resolve(_data),
        (err: Error) => reject(err),
      ),
    );
  }

  public static startSession(
    sessionId: string,
    handleSuccess: (_data: { session: SessionResponseDto; room: VideoRoomSummary }) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'stream/start/' + sessionId;

    return ApiBaseService.Post(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static startSessionPromise(
    sessionId: string,
  ): Promise<{ session: SessionResponseDto; room: VideoRoomSummary }> {
    const uriPath = SessionService.basePath() + 'stream/start/' + sessionId;
    return new Promise<{ session: SessionResponseDto; room: VideoRoomSummary }>((resolve, reject) =>
      SessionService.startSession(sessionId, resolve, reject),
    );
  }

  public static feedbackStream(
    sessionId: string,
    handleSuccess: (_data: any) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'stream/feedback/' + sessionId;

    ApiBaseService.Post(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static endStream(sessionId: string, handleSuccess: (_data: any) => void, handleError: (err: Error) => void) {
    const uriPath = SessionService.basePath() + 'stream/end/' + sessionId;

    ApiBaseService.Post(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static score(
    id: string,
    payload: SessionScoreRequestDto,
    handleSuccess: (_data: SessionResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'score/' + id;

    ApiBaseService.Patch(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data.scoreResult);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static changeScore(
    sessionId: string,
    scoringId: string,
    payload: { value: number },
    handleSuccess: (_data: number) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + `${sessionId}/scoreResult/${scoringId}`;

    ApiBaseService.Patch(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static note(
    id: string,
    payload: SessionTeachableMomentRequestDto,
    handleSuccess: (_data: SessionResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'note/' + id;

    ApiBaseService.Patch(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getTeachableMoments(id: string): Promise<TeachableMomentDto[]> {
    const uriPath = SessionService.basePath() + id + '/notes';
    return new Promise<TeachableMomentDto[]>((resolve, reject) =>
      ApiBaseService.Get(
        uriPath,
        {},
        (code: number, data: TeachableMomentDto[]) => resolve(data),
        (err: Error) => reject(err),
      ),
    );
  }

  public static addParticipantIdToSession(id: string, payload: SessionAddParticipantRequestDto) {
    const uriPath = SessionService.basePath() + 'participant/' + id;
    console.log(':>> Useless method called addParticipantIdToSession');

    return new Promise<SessionRecordResponseDto>((resolve, reject) =>
      ApiBaseService.Patch(
        uriPath,
        payload,
        (_code: number, _data: SessionRecordResponseDto) => resolve(_data),
        (err: Error) => reject(err),
      ),
    );
  }

  public static getCoachSessionRecordMedia(
    sessionId: string,
    abortSignal?: AbortSignal,
  ): Promise<SessionRecordDownloadResponseDto> {
    const uriPath = SessionService.basePath() + 'coaching-record/' + sessionId;
    return new Promise<SessionRecordDownloadResponseDto>((resolve, reject) =>
      ApiBaseService.Get(
        uriPath,
        {},
        (code: number, data: SessionRecordDownloadResponseDto) => resolve(data),
        (err: Error) => reject(err),
        abortSignal,
      ),
    );
  }

  public static getFeedbackSessionRecordMedia(sessionId: string): Promise<SessionRecordDownloadResponseDto> {
    const uriPath = SessionService.basePath() + 'feedback-record/' + sessionId;
    return new Promise<SessionRecordDownloadResponseDto>((resolve, reject) =>
      ApiBaseService.Get(
        uriPath,
        {},
        (code: number, data: SessionRecordDownloadResponseDto) => resolve(data),
        (err: Error) => reject(err),
      ),
    );
  }

  /**
   * @deprecated
   */
  public static toggleSessionRecording(sessionId: string): Promise<SessionRecordRecordingResponseDto> {
    const uriPath = `${SessionService.basePath()}recording/${sessionId}`;
    return new Promise<SessionRecordRecordingResponseDto>((resolve, reject) =>
      ApiBaseService.Patch(
        uriPath,
        {},
        (code: number, data: SessionRecordRecordingResponseDto) => resolve(data),
        (err: Error) => reject(err),
      ),
    );
  }

  public static dailyToggleSessionRecord(sessionId: string): Promise<SessionRecordRecordingResponseDto> {
    const uriPath = `${SessionService.basePath()}recording/${sessionId}`;
    return new Promise<SessionRecordRecordingResponseDto>((resolve, reject) =>
      ApiBaseService.Patch(
        uriPath,
        {},
        (code: number, data: SessionRecordRecordingResponseDto) => resolve(data),
        (err: Error) => reject(err),
      ),
    );
  }

  public static exportSessionLog(
    filter: SessionLogExportFilterDto,
    handleSuccess: (_data: ArrayBuffer) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'export/xlsx';

    ApiBaseService.PostArrayBufferAccept(
      uriPath,
      filter,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static exportReportingSessionLog(
    filter: InsightsEvaluationCoachFilterDto,
    handleSuccess: (_data: ArrayBuffer) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionService.basePath() + 'reportingExport/xlsx';
    ApiBaseService.PostArrayBufferAccept(
      uriPath,
      filter,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getToken(sessionId: string, shareToken?: string): Promise<VideoRoomTokenResponseDto> {
    const uriPath = SessionService.basePath() + sessionId + '/token?shareToken=' + shareToken;
    return new Promise<VideoRoomTokenResponseDto>((resolve, reject) =>
      ApiBaseService.Get(
        uriPath,
        {},
        (code: number, data: VideoRoomTokenResponseDto) => resolve(data),
        (err: Error) => reject(err),
      ),
    );
  }
}
