import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Alert from '../../utils/alert';
import { SessionStatus, USER_ROLES } from '../../common/enums';
import AbstractModal from '../../components/AbstractModal';
import CustomMultiSelect, { ICustomSelectedItem } from '../../components/CustomMultiSelect';
import ModalContent from '../../components/ModalContent';
import SwitchButton from '../../components/SwitchButton';
import { CaseService } from '../../service/case.service';
import { CategoryService } from '../../service/category.service';
import { CaseResponseDto, CaseTemplateSummaryDto } from '../../service/dto/case.dto';
import { CategoryListItemDto } from '../../service/dto/category.dto';
import { RequestTimeSlotsRequest, SessionResponseDto } from '../../service/dto/session.dto';
import { SessionService } from '../../service/session.service';
import { getDateAndTimeDuration } from '../../utils';
import { setUserRole } from '../../utils/role';
import { IRouteComponent } from '../../routes';
import BookSessionContent from './BookSessionContent';
import ScheduleTable from './ScheduleTable';
import { schedulePageSize } from '../../common/constants';
import ReactPaginate from 'react-paginate';
import DefaultHeader from '../../components/DefaultHeader';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  setOnlyMineSelector,
  setOnlyScheduledSelector,
  setScheduleCasesFilterSelector,
  setScheduleCategoriesFilterSelector,
} from '../../redux/actions';
import CustomSelect, { ICustomSelectItem } from '../../components/CustomSelect';
import { AvailableCoachesResponseDto } from '../../service/dto/available-coaches.response.dto';
import { UserService } from '../../service/user.service';
import SingleCheckboxInput from '../../components/SingleCheckboxInput';
import { SessionFilterLocation } from '../../common/enums';
import InputLabel from '../../components/InputLabel';
import Textarea from '../../components/Textarea';

enum COMPONENT_STATE {
  LIST,
  CANCEL,
  BOOK,
  REQUESTSLOT,
}

const ScheduleList = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState(COMPONENT_STATE.LIST);
  const [sessionList, setSessionList] = useState<SessionResponseDto[]>([]);
  const [caseList, setCaseList] = useState<ICustomSelectedItem[]>([]);
  const [caseData, setCaseData] = useState<CaseResponseDto>();
  const [certifiedCaseList, setCertifiedCaseList] = useState<ICustomSelectedItem[]>([]);
  const [roles, setRoles] = useState<USER_ROLES[]>([USER_ROLES.Trainee]);
  const { selectedCases }: { selectedCases: string[] } = useAppSelector(
    (state: any) => state.scheduleCasesFilterReducer,
  );
  const { selectedCategories }: { selectedCategories: string[] } = useAppSelector(
    (state: any) => state.scheduleCategoriesFilterReducer,
  );
  const { onlyScheduled }: { onlyScheduled: boolean } = useAppSelector((state: any) => state.onlyScheduledReducer);
  const { onlyMine }: { onlyMine: boolean } = useAppSelector((state: any) => state.onlyMineReducer);
  const [avaibleCoachFilter, setAvaibleCoachFilter] = useState('');
  const [selectedSession, setSelectedSession] = useState<SessionResponseDto>();
  const [categoryNames, setCategoryNames] = useState<ICustomSelectedItem[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [coaches, setCoaches] = useState<ICustomSelectItem[]>([]);
  const [locationFilter, setLocationFilter] = useState<SessionFilterLocation>(SessionFilterLocation.Any);
  const [requestSlotMessage, setRequestSlotMessage] = useState<string>('');

  const checkboxStyle =
    'appearance-none h-4 w-4 border-gray border-2 rounded-full cursor-pointer transition duration-200 ease-in-out checked:bg-primary checked:border-primary';

  useEffect(() => {
    const userRoles = setUserRole({ currentUser, currentInstitution });
    setRoles(userRoles);
    setPage(0);
    serviceAvailableCoaches();
    serviceGetCaseList();
    serviceCategoryFetch();
    serviceGetCertifiedCases();
  }, []);

  useEffect(() => {
    serviceGetSessionCount();
    serviceGetSessionList();
  }, [onlyScheduled, selectedCases, selectedCategories, onlyMine, roles, avaibleCoachFilter, locationFilter]);

  useEffect(() => {
    serviceGetSessionList();
  }, [page]);

  const handleCategoryChange = (categoryIds: string[]) => {
    dispatch(setScheduleCategoriesFilterSelector({ selectedCategories: categoryIds }));
  };

  const serviceCategoryFetch = () => {
    CategoryService.list(
      (data: CategoryListItemDto[]) => {
        setCategoryNames(
          data.map(category => {
            return { value: category.id, label: category.name };
          }),
        );
      },
      () => {},
    );
  };

  const onPageChange = (event: { selected: number }) => {
    setPage(event.selected);
  };

  // SERVICE FETCH
  const serviceGetSessionList = () => {
    SessionService.list(
      getQueryData(),
      (data: Array<SessionResponseDto>) => {
        setSessionList(data);
      },
      error => {
        setSessionList([]);
      },
    );
  };

  const serviceAvailableCoaches = () => {
    UserService.availableCoaches(
      (_data: AvailableCoachesResponseDto[]) => {
        const coachList: ICustomSelectItem[] = _data.map(user => {
          return {
            label: user.coachName,
            value: user.coachId,
          };
        });
        setCoaches(coachList);
      },
      error => {
        setCoaches([]);
      },
    );
  };

  const serviceGetSessionCount = () => {
    SessionService.count(
      getQueryData(),
      (data: number) => {
        setTotalPages(Math.floor((data - 1) / schedulePageSize) + 1);
      },
      error => {
        setTotalPages(0);
        setPage(0);
      },
    );
  };

  const getQueryData = () => {
    return {
      cases: selectedCases,
      onlyScheduled: onlyScheduled,
      onlyMine: onlyMine,
      role: roles,
      page: page,
      pageSize: schedulePageSize,
      categories: selectedCategories,
      coach: avaibleCoachFilter,
      location: locationFilter,
    };
  };

  const handleCaseChange = (caseIds: string[]) => {
    dispatch(setScheduleCasesFilterSelector({ selectedCases: caseIds }));
  };

  const serviceGetCaseList = () => {
    CaseService.listPublished(
      (data: Array<CaseTemplateSummaryDto>) => {
        setCaseList(
          data.map(ct => {
            return { label: ct.name, value: ct.id };
          }),
        );
      },
      () => {},
    );
  };

  const serviceGetCertifiedCases = () => {
    CaseService.listCertified(
      { search: '', rolesFilter: roles },
      (data: Array<CaseResponseDto>) => {
        const modifiedCaseList = data.map(v => {
          return { value: v.id, label: v.name };
        });
        setCertifiedCaseList(modifiedCaseList);
      },
      () => {},
    );
  };

  const serviceGetGyId = (chosenCaseId: string) => {
    CaseService.getById(
      chosenCaseId,
      (data: CaseResponseDto) => {
        setCaseData(data);
      },
      error => {},
    );
  };

  // SERVICE CREATE
  const serviceBook = () => {
    SessionService.book(
      selectedSession!.id,
      (data: SessionResponseDto) => {
        serviceGetSessionList();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.sessionBooked'));
      },
      error => {
        console.log(error);
        Alert.warning(t('errorMessages.sessionNotBooked'));
      },
    );
  };

  const serviceCancel = () => {
    SessionService.cancel(
      selectedSession!.id,
      (data: SessionResponseDto) => {
        serviceGetSessionList();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.sessionCanceled'));
      },
      error => {
        console.log(error);
        Alert.warning(t('errorMessages.sessionNotCanceled'));
      },
    );
  };

  const serviceRequestSlot = () => {
    const payload: RequestTimeSlotsRequest = {
      message: requestSlotMessage,
    };
    SessionService.requestSessionSlot(
      payload,
      () => {
        Alert.success(t('successMessages.slotRequestSuccess'));
      },
      error => {
        console.log(error);
        Alert.warning(t('errorMessages.slotRequestFailed'));
      },
    );
  };

  // SESSION ACTIONS
  const cancelSession = (id: string, number: number) => {
    const chosenSession = sessionList[number];
    setSelectedSession(chosenSession);
    setState(COMPONENT_STATE.CANCEL);
  };

  const bookSession = (id: string, number: number) => {
    const chosenSession = sessionList[number];
    serviceGetGyId(chosenSession.caseTemplateSummary.id);
    setSelectedSession(chosenSession);
    setState(COMPONENT_STATE.BOOK);
  };

  const detailsSession = (id: string, number: number) => {
    navigate('/session/details/' + id);
  };

  const resultSession = (id: string, number: number) => {
    navigate(`/session/result/${id}`);
  };

  const handleBookSessionSubmit = () => {
    serviceBook();
  };

  const handleCancelSessionSubmit = () => {
    serviceCancel();
  };

  const handleRequestSessionSlot = () => {
    setState(COMPONENT_STATE.REQUESTSLOT);
  };

  const renderTime = (item: SessionResponseDto) => {
    const { startTime, endTime, monthLabel, day } = getDateAndTimeDuration(item);
    return `${monthLabel} ${day}, ${startTime} - ${endTime}`;
  };
  const changeLocationFilter = (filterCase: FilterLocationType) => {
    setLocationFilter(filterCase);
  };

  const filterBar = () => {
    return (
      <div className='flex justify-end mb-2'>
        <div className='w-1/4  h-9'>
          <CustomMultiSelect
            placeholderAll={t('sessionfilter.placeholder.allCases')}
            placeholderSelected={t('sessionfilter.selectedCases')}
            list={caseList}
            handleChange={handleCaseChange}
            values={selectedCases}
          />
        </div>
        <div className='flex w-1/4 ml-6 h-9'>
          <CustomSelect
            placeholder={t('sessionfilter.placeholder.allCoaches')}
            list={coaches}
            clearable={true}
            handleChange={(coach: ICustomSelectItem | null) => {
              setAvaibleCoachFilter(coach?.value ?? '');
            }}
          />
        </div>
        <div className='flex ml-6  h-9 mr-6 w-1/4'>
          <CustomMultiSelect
            placeholderAll={t('sessionfilter.placeholder.allCategories')}
            placeholderSelected={t('sessionfilter.selectedCategories')}
            list={categoryNames}
            handleChange={handleCategoryChange}
            values={selectedCategories}
          />
        </div>
      </div>
    );
  };

  const generalFilteBar = () => {
    return (
      <div className='flex mt-6 justify-end pb-2'>
        <div className='flex justify-align pt-1'>
          <SingleCheckboxInput
            id={t('sessionfilter.any')}
            label={t('sessionfilter.any')}
            handleChange={() => changeLocationFilter(SessionFilterLocation.Any)}
            checked={locationFilter == SessionFilterLocation.Any}
            style={checkboxStyle}
          />
          <SingleCheckboxInput
            id={t('sessionfilter.online')}
            label={t('sessionfilter.online')}
            handleChange={() => changeLocationFilter(SessionFilterLocation.Online)}
            checked={locationFilter == SessionFilterLocation.Online}
            style={checkboxStyle}
          />
          <SingleCheckboxInput
            id={t('sessionfilter.offline')}
            label={t('sessionfilter.offline')}
            handleChange={() => changeLocationFilter(SessionFilterLocation.Offline)}
            checked={locationFilter == SessionFilterLocation.Offline}
            style={checkboxStyle}
          />
        </div>
        <div className='border-r-2 mr-6 mb-2.5' />
        {roles.includes(USER_ROLES.Trainee) && (
          <div className='flex items-center mr-6'>
            <SwitchButton
              option={{
                id: '',
                title: `${t('sessionfilter.onlyScheduled')}`,
                status: onlyScheduled,
              }}
              handleOption={o => {
                dispatch(setOnlyScheduledSelector({ onlyScheduled: !o.status }));
              }}
            />
          </div>
        )}
        {roles.includes(USER_ROLES.Trainee) && (
          <div className='flex items-center mr-8'>
            <SwitchButton
              option={{
                id: '',
                title: `${t('sessionfilter.onlyMine')}`,
                status: onlyMine,
              }}
              handleOption={o => {
                dispatch(setOnlyMineSelector({ onlyMine: !o.status }));
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const renderRequestSlotModal = () => {
    return (
      <AbstractModal
        label={t('schedule.requestSlotModal.title')}
        leftBtn={{
          label: t('buttons.cancel'),
          onClick: () => {
            setState(COMPONENT_STATE.LIST);
          },
        }}
        rightBtn={{
          label: t('buttons.send'),
          icon: 'bi-check2-circle',
          onClick: () => {
            serviceRequestSlot();
            setState(COMPONENT_STATE.LIST);
          },
        }}>
        <form className='w-full'>
          <div className='w-full mb-6'>
            <InputLabel label={t('schedule.requestSlotModal.messageLabel')} />
            <Textarea
              placeholder={t('schedule.requestSlotModal.messagePlaceholder')}
              value={requestSlotMessage}
              handleChange={(e: any) => setRequestSlotMessage(e.target.value)}
              required
            />
          </div>
        </form>
      </AbstractModal>
    );
  };

  return (
    <div className='ml-6 mr-6 mt-6'>
      <div className='rounded-lg bg-white pb-2 my-6 shadow'>
        <DefaultHeader
          title={t('schedule.title')}
          primaryAction={{
            title: t('buttons.createSessionSlot'),
            icon: 'bi bi-plus-circle',
            onClick: () => {
              navigate('/session/create/regular');
            },
            hide: !(certifiedCaseList.length > 0),
          }}
          floatingAction={{
            title: t('buttons.requestSessionSlot'),
            onClick: () => {
              handleRequestSessionSlot();
            },
            textColor: 'text-negative-light',
            show: true,
          }}
        />
        <div>{generalFilteBar()}</div>
        <div>{filterBar()}</div>
        <ScheduleTable
          sessionList={sessionList}
          roles={roles}
          detailsSession={detailsSession}
          resultSession={resultSession}
          cancelSession={cancelSession}
          currentUser={currentUser}
          currentInstitution={currentInstitution}
        />
        {!!totalPages && (
          <ReactPaginate
            previousLabel={<i className='bi bi-chevron-left' />}
            breakLabel='...'
            nextLabel={<i className='bi bi-chevron-right' />}
            onPageChange={onPageChange}
            pageRangeDisplayed={5}
            pageCount={totalPages}
            containerClassName='pagination flex justify-center text-gray-dark'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            activeClassName='active'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            forcePage={page}
          />
        )}

        {state == COMPONENT_STATE.REQUESTSLOT && renderRequestSlotModal()}

        {state == COMPONENT_STATE.BOOK && (
          <AbstractModal
            label={selectedSession?.caseTemplateSummary.name}
            leftBtn={{
              label: t('buttons.cancel'),
              onClick: () => {
                setState(COMPONENT_STATE.LIST);
              },
            }}
            rightBtn={{
              label: t('buttons.bookThisSession'),
              icon: 'bi-check2-circle',
              onClick: () => {
                handleBookSessionSubmit();
              },
            }}
            cancelButton={{
              onClick: () => {
                setState(COMPONENT_STATE.LIST);
              },
            }}>
            <BookSessionContent session={selectedSession!} caseData={caseData} />
          </AbstractModal>
        )}

        {state == COMPONENT_STATE.CANCEL && (
          <AbstractModal
            position={{ width: 'w-1/4', left: 'left-1/3' }}
            label={t('modals.cancelSession')}
            leftBtn={{
              label: t('buttons.cancel'),
              onClick: () => {
                setState(COMPONENT_STATE.LIST);
              },
            }}
            rightBtn={{
              label: t('buttons.yesCancel'),
              onClick: () => {
                handleCancelSessionSubmit();
              },
            }}
            toDelete>
            <ModalContent
              entity='schedule'
              title=''
              subtitle={`${t('schedule.cancelModal.subtitleFirst')} “${selectedSession!.caseTemplateSummary.name}.” ${t(
                'schedule.cancelModal.subtitleSecond',
              )} “${renderTime(selectedSession!)}”.`}
            />
          </AbstractModal>
        )}
      </div>
    </div>
  );
};

export default ScheduleList;
